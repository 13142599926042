import { FC } from 'react';

import { Table } from '@vakantiesnl/types';
import cs from 'classnames';

import styles from './SimpleTable.module.css';

type Props = {
	table: Table;
	hasHeaders?: boolean;
	className?: string;
};

const SimpleTable: FC<Props> = ({ table, hasHeaders, className }) => {
	const headers = table.tableData[0];
	const entries = table.tableData.slice(1, table.tableData.length);

	return (
		<table className={cs(styles.simpleTable, className)}>
			{hasHeaders && (
				<thead>
					<tr>
						{headers.map((header, key) => (
							<th key={key}>{header}</th>
						))}
					</tr>
				</thead>
			)}
			<tbody>
				{entries.map((entry, key) => (
					<tr key={key}>
						{entry.map((tableElement, key) => (
							<td className={styles.tableEntry} key={key}>
								{tableElement}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export { SimpleTable };
