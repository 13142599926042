import { FC } from 'react';

import { Heading } from '@vakantiesnl/components/src/__LEGACY__/atoms/Heading';
import { Icon } from '@vakantiesnl/components/src/__LEGACY__/atoms/Icon';

import styles from './HeadingWithTick.module.css';

type HeadingWithTickProps = {
	title: string;
};

export const HeadingWithTick: FC<HeadingWithTickProps> = ({ title }) => (
	<Heading headingLevel="h4" className={styles.heading}>
		<Icon type="vaknlCheck" /> {title}
	</Heading>
);
