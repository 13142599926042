import { FC, memo } from 'react';

import styles from './Span.module.css';

type Props = {
	title: string;
};

const Span: FC<Props> = ({ title }) => <span className={styles.span}>{title}</span>;

const memoizedSpan = memo(Span);
export { memoizedSpan as Span };
